/* You can add global styles to this file, and also import other style files */
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "src/assets/styles/variables.scss";

body {
  min-height: 100vh;
}

* {
  border-radius: 0 !important;
}

.wizard-content {
  width: 100%;
  min-height: 80vh;
  font-size: 1.2rem;
  background-color: $base-color;
}

@media all and (min-width: 768px) {
  .wizard-content {
    padding: 0 10px;
  }
}


.wizard-content-body {
  width: 100%;
}

@media all and (max-width: 767px) {
  .wizard-content-body {
    padding: 0.2rem !important;
  }

  label:not(.form-check-label) {
    padding-left: 2rem !important;
  }

  select, input {
    padding-left: 0.3rem !important;
  }
}


.form-control {
  font-size: unset !important;
}
